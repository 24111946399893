import React from 'react';
import classNames from 'classnames';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { RssWidgetIconType } from '@wix/communities-blog-client-common';
import stylesParams from '../stylesParams';
import s from './widget.scss';

type Props = {
  rssFeedLink: string | undefined;
};

const RssButton: React.FC<WidgetProps<Props>> = ({ rssFeedLink }) => {
  const styles = useStyles();
  const conditionalProps: React.HTMLAttributes<HTMLAnchorElement> = rssFeedLink
    ? { className: s.root }
    : {
        className: classNames(s.root, s.disabled),
        onClick: (e) => e.preventDefault(),
      };

  return (
    <a
      data-hook="rss-button-root"
      href={rssFeedLink}
      rel="noopener noreferrer"
      target="_blank"
      {...conditionalProps}
    >
      <>
        {
          iconMap[
            styles.get(
              stylesParams['widget-iconBackgroundType'],
            ) as RssWidgetIconType
          ]
        }
        <span hidden data-hook="blog-loaded-indicator" />
      </>
    </a>
  );
};

const iconMap: Record<RssWidgetIconType, JSX.IntrinsicElements['svg']> = {
  [RssWidgetIconType.Square]: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      data-hook="feed-button__square"
      className={s.typeSquare}
    >
      <g fill="none" fill-rule="evenodd">
        <rect width="36" height="36" rx="4" />
        <path d="M8.008 8v3.817c8.916.006 16.164 7.262 16.17 16.183h3.83c-.003-5.514-2.245-10.508-5.868-14.132C18.517 10.245 13.522 8.002 8.008 8z" />
        <path d="M8.001 14.8v3.82a9.312 9.312 0 0 1 6.617 2.748 9.336 9.336 0 0 1 2.743 6.626V28h3.837a13.174 13.174 0 0 0-3.873-9.325A13.156 13.156 0 0 0 8 14.8zM12.532 23.46a2.63 2.63 0 0 1 .003 3.74h-.003a2.643 2.643 0 0 1-1.878.776A2.642 2.642 0 0 1 8.78 27.2a2.634 2.634 0 0 1 0-3.74v.001a2.642 2.642 0 0 1 1.875-.777c.735 0 1.395.295 1.878.777z" />
      </g>
    </svg>
  ),
  [RssWidgetIconType.Circle]: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      data-hook="feed-button__circle"
      className={s.typeCircle}
    >
      <g fill="none" fill-rule="evenodd">
        <rect width="36" height="36" rx="18" />
        <path d="M12.005 12v2.29c5.347.004 9.694 4.358 9.698 9.71H24a11.968 11.968 0 0 0-3.52-8.48A11.956 11.956 0 0 0 12.006 12z" />
        <path d="M12 16.08v2.292c1.496 0 2.912.588 3.97 1.649a5.602 5.602 0 0 1 1.647 3.975V24h2.302a7.904 7.904 0 0 0-2.324-5.595A7.894 7.894 0 0 0 12 16.08zM14.72 21.276a1.578 1.578 0 0 1 0 2.244c-.29.29-.686.466-1.128.466-.44 0-.834-.177-1.125-.466a1.58 1.58 0 0 1 0-2.243 1.588 1.588 0 0 1 2.252 0z" />
      </g>
    </svg>
  ),
  [RssWidgetIconType.None]: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      data-hook="feed-button__none"
      className={s.typeNone}
    >
      <path
        fill-rule="evenodd"
        d="M0 6.899a13.262 13.262 0 0 1 9.327 3.819c2.392 2.358 3.87 5.605 3.875 9.19H9.365V19.9h-.001a9.132 9.132 0 0 0-2.745-6.53A9.388 9.388 0 0 0 0 10.664V6.899zM0 0a20.086 20.086 0 0 1 14.132 5.783C17.755 9.354 19.997 14.277 20 19.71h-3.83C16.164 10.919 8.916 3.768 0 3.76V0zm4.534 15.55c.485.476.78 1.123.782 1.844a2.571 2.571 0 0 1-.78 1.84l-.002.002A2.664 2.664 0 0 1 2.655 20c-.733 0-1.392-.29-1.876-.765a2.57 2.57 0 0 1 0-3.686 2.664 2.664 0 0 1 1.876-.766c.736 0 1.395.292 1.88.766z"
      />
    </svg>
  ),
};

export default RssButton;
